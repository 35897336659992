function getYMD(time, isHideHours) {
    if (!time) {
        return '-'
    }
    if (`${time}`.length == 10) {
        time = time * 1000
    }
    const t = new Date(time);
    time = t.getFullYear();
    time += `/${t.getMonth() + 1 > 9 ? (t.getMonth() + 1) : '0' + (t.getMonth() + 1)}`
    time += `/${t.getDate() > 9 ? t.getDate() : '0' + t.getDate()}`
    if (!isHideHours) {
        time += `  ${t.getHours() > 9 ? t.getHours() : '0' + t.getHours()}`
        time += `:${t.getMinutes() > 9 ? t.getMinutes() : '0' + t.getMinutes()}`
        time += `:${t.getSeconds() > 9 ? t.getSeconds() : '0' + t.getSeconds()}`
    }
    return time
}

/*
 *国际标准时间转换为普通时间格式
 */
function turnTime(time,isHideHours) {
    if (!time) {
        return '-'
    }
    var t = new Date(time)
    time = t.getFullYear();
    time += `/${t.getMonth() + 1 > 9 ? (t.getMonth() + 1) : '0' + (t.getMonth() + 1)}`
    time += `/${t.getDate() > 9 ? t.getDate() : '0' + t.getDate()}`
    if (!isHideHours) {
        time += `  ${t.getHours() > 9 ? t.getHours() : '0' + t.getHours()}`
        time += `:${t.getMinutes() > 9 ? t.getMinutes() : '0' + t.getMinutes()}`
        time += `:${t.getSeconds() > 9 ? t.getSeconds() : '0' + t.getSeconds()}`
    }
    return time
}

/*
 *国际标准时间转换为普通时间格式
 */
function formateTime(time, formate = 'y-m-d h:f:s') {
    if (!time) {
        return '-'
    }
    let res = formate;
    `${time}`.length == 10 && (time = Number(time) * 1000);
    const t = new Date(time)
    const year = t.getFullYear();
    const month = t.getMonth() + 1 > 9 ? (t.getMonth() + 1) : '0' + (t.getMonth() + 1)
    const day = t.getDate() > 9 ? t.getDate() : '0' + t.getDate()
    const hours = t.getHours() > 9 ? t.getHours() : '0' + t.getHours();
    const minute = t.getMinutes() > 9 ? t.getMinutes() : '0' + t.getMinutes();
    const sceond = t.getSeconds() > 9 ? t.getSeconds() : '0' + t.getSeconds();

    res = res.replace('y', year);
    res = res.replace('m', month);
    res = res.replace('d', day);
    res = res.replace('h', hours);
    res = res.replace('f', minute);
    res = res.replace('s', sceond);
    return res;
}

export {
    getYMD,
    turnTime,
    formateTime
}