function getItem(key) {
    if (!key) {
        console.log('!!!err:$FN_getItem 参数为必传')
        return ''
    }
    let val = window.localStorage.getItem(key) || '';
    try {
        val = JSON.parse(val)
    } catch (error) {
        console.log(`getIem::${key}_该值为非对象数组`)
    }
    return val
}

function setItem(key, val) {
    if (!key) {
        console.log('!!!err:$FN_setItem 参数为必传')
        return ''
    }
    let value = val
    if (typeof value != 'string') {
        try {
            value = JSON.stringify(value)
        } catch (error) {
            console.log(`setItem::${key}_该值为非对象数组`)
        }
    }

    window.localStorage.setItem(key, value);
}

function removeItem(key) {
    if (!key) {
        console.log('!!!err:$FN_removeItem 参数为必传')
        return ''
    }
    window.localStorage.removeItem(key);
}

export {
    getItem,
    setItem,
    removeItem
}