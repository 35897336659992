<template>
  <div id="app-body">
      <router-view></router-view>
  </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {}
    },
    created() {
        window.alert = function(msg) {
            console.log(msg)
        }

        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        let name = vm.$localStorage.getItem(USER_NAME);
        const { ADMIN_URL, ADMIN_TOKEN } = vm.$config.base;
        if (name) {
            vm.$http({
                url: `${ADMIN_URL}javaApi/iot/device/types`,
                headers: { 'access-token': ADMIN_TOKEN },
                except: true
            }).then(res => {
                window.deviceTypes = res.data
            })
        }
    },
    methods: {

    }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
