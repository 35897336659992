const factory = {
	path: '/factory',
	redirect: '/factory/index',
	name: 'Factory',
	component: () => import('@/Layout'),
	meta: {
		title: '质量检测',
	},
	children: [
		{
			path: 'index',
			component: () => import('@/pages/factory/index'),
			name: 'FactoryIndex',
			meta: { title: '质量检测' },
		},
		{
			path: 'login',
			component: () => import('@/pages/factory/login'),
			name: 'FactoryLogin',
			meta: { title: '登录' },
		},
		{
			path: 'checkInfo',
			component: () => import('@/pages/factory/checkInfo'),
			name: 'CheckInfo',
			meta: { title: '质检' },
		},
		{
			path: 'description',
			component: () => import('@/pages/factory/description'),
			name: 'FactoryDescription',
			meta: { title: '使用说明' },
		},
		{
			path: 'quality/qualityList',
			component: () => import('@/pages/factory/quality/qualityList'),
			name: 'RyQualityList',
			meta: { title: '返厂定损' },
		},
		{
			path: 'quality/createOrder',
			component: () => import('@/pages/factory/quality/createOrder'),
			name: 'DeviceCreateOrder',
			meta: { title: '新建返厂单' },
		},
		{
			path: 'quality/history/:id',
			component: () => import('@/pages/factory/quality/qualityHistory'),
			name: 'RyQualityHistory',
			meta: { title: '工单详情' },
		},
		{
			path: 'quality/info/:id',
			component: () => import('@/pages/factory/quality/qualityOrderInfo'),
			name: 'FactoryQualityOrderInfo',
			meta: { title: '质检单' },
		},
		{
			path: 'afterSale/uploadImgPage',
			component: () => import('@/pages/factory/afterSale/uploadImgPage'),
			name: 'RyUploadImgPage',
			meta: { title: '异常探头处理' },
		},
		{
			path: 'afterSale/addUploadImgPage',
			component: () => import('@/pages/factory/afterSale/addUploadImgPage'),
			name: 'RyAddUploadImgPage',
			meta: { title: '新建记录' },
		},
		{
			path: 'afterSale/acceptanceCheckWarningList',
			component: () => import('@/pages/factory/afterSale/acceptanceCheckWarningList'),
			name: 'AcceptanceCheckWarningList',
			meta: { title: '验收记录' },
		},
		{
			path: 'afterSale/determineRes',
			component: () => import('@/pages/factory/afterSale/determineRes'),
			name: 'DetermineRes',
			meta: { title: '复核订单' },
		},
		{
			path: 'stock/checkDevice',
			component: () => import('@/pages/factory/stock/checkDevice'),
			name: 'CheckDevice',
			meta: { title: '校验' },
		},
		{
			path: 'quality/tlQaInfo/:id',
			component: () => import('@/pages/factory/quality/tlQaInfo'),
			name: 'TlQaInfo',
			meta: { title: '质检详情' },
		},
		{
			path: 'quality/tlQa/:id',
			component: () => import('@/pages/factory/quality/tlQa'),
			name: 'TlQa',
			meta: { title: '控制盒质检' },
		},
	],
};
export default factory;