const ryProbe = {
    path: '/ryProbe',
    redirect: '/ryProbe/list',
    name: 'RyProbe',
    component: () => import('@/Layout'),
    meta: {
        title: '质量检测'
    },
    children: [
        {
            path: 'list',
            component: () => import('@/pages/ryProbe/list'),
            name: 'RyProbeList',
            meta: { title: '质量检测' }
        },
        {
            path: 'info',
            component: () => import('@/pages/ryProbe/info'),
            name: 'RyProbeInfo',
            meta: { title: '质量检测' }
        },
        {
            path: 'afterSale/login',
            component: () => import('@/pages/ryProbe/afterSale/login'),
            name: 'Login',
            meta: { title: '质量检测' }
        },
        {
            path: 'afterSale/list',
            component: () => import('@/pages/ryProbe/afterSale/list'),
            name: 'RyProbeAfterSaleList',
            meta: { title: '返厂维护' }
        },
        {
            path: 'afterSale/info/:id',
            component: () => import('@/pages/ryProbe/afterSale/info'),
            name: 'RyProbeAfterSaleInfo',
            meta: { title: '返厂维护详情' }
        },
        {
            path: 'afterSale/warningList',
            component: () => import('@/pages/ryProbe/afterSale/warningProbeList'),
            name: 'RyWarningProbeList',
            meta: { title: '异常探头记录' }
        },
        {
            path: 'preSale/list',
            component: () => import('@/pages/ryProbe/preSale/checkTest'),
            name: 'RyPreSaleCheckTestList',
            meta: { title: '出厂校验' }
        }
    ]
}
export default ryProbe;