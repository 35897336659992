import axios from 'axios';
import { Dialog } from 'vant';
import { base } from '@/config/index'
// create an axios instance
const service = axios.create({
    baseURL: base.BASE_URL,
    // request timeout
    timeout: 30000,
    // 请求失败重试
    retry: base.RETRY_COUNT,
    // 重试间隔时间
    retryDelay: base.RETRY_DELAY
});

// request interceptor
service.interceptors.request.use(
    config => {
        // if (store.getters.token) {
        //     config.headers['access-token'] = getToken();
        // }
        config.type && (config.method = config.type);
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data;
        if (res.code !== 1 && !response.config.except) {
            Dialog.alert({
							title: '提示',
							message: res.message
								? `${res.message.sqlMessage || res.message}--${response.config.url}`
								: '服务器繁忙！请稍后再试~',
						});
            return Promise.reject(res.message || 'Error');
        } else {
            return res;
        }
    },
    error => {
        // 请求超时时隔10秒再次重试1次
        if (error.message.indexOf('timeout') > -1) {
            const config = error.config
            if (!config || !config.retry) {
                return Promise.reject(error)
            }
            config.__retryCount = config.__retryCount || 0
            if (config.__retryCount >= config.retry) {
                Dialog.alert({
                    message: error.message,
                    title: 'timeout'
                });
                return Promise.reject(error)
            }
            config.__retryCount += 1
            var backoff = new Promise(function (resolve) {
                setTimeout(function () {
                    resolve();
                }, config.retryDelay || 1)
            })
            return backoff.then(function () {
                return service(config)
            })
        } else {
            console.log('err' + error); // for debug
            Dialog.alert({
                message: error.message || '网络错误',
                title: '提示'
            });
        }
    }
);

export default service;